import Vue from 'vue'
import VueRouter from 'vue-router'
import Core from '@ysl-modules-rtw/core'
import HomeView from './views/index.vue'
//
import App from './App.vue'
import './assets/styles/index.sass'

Vue.use(Core)
Vue.use(VueRouter)

const routes = [
  { path: '/', component: HomeView }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

new Vue({
  render: h => h(App),
  router
}).$mount('.app')
