var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"app"},[_c('v-header',{staticStyle:{"filter":"invert(1)"}}),_c('v-cover',{attrs:{"src":_vm.isMobile ? 'https://res.cloudinary.com/neuvieme-page/video/upload/f_auto/v1670419767/app-eoy/videos/1_mobile_eoy.mp4' : 'https://res.cloudinary.com/neuvieme-page/video/upload/v1670434827/app-eoy/videos/original_video.mp4',"muteDisplay":true,"logoImage":"/desktop/logo-drop-shadow.png","logo-image-width":['50%', '90%'],"ratio":16 / 9,"fetchPriority":'high',"lazy":'eager'}}),_c('v-space',{style:({ height: 'var(--module-spacing-y-eoy)' })}),_c('v-duo',{style:({ padding: _vm.isMobile ? '0 62px' : '0 10vw' }),attrs:{"gutter-size":'5.2vw',"slides":[
    {
      mediaLink: 'https://www.ysl.com/search?cgid=holidays-arrivals&utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing',
      src:
        '/edito/WW/1_SLE141022_0834_RGB.jpg',
      ratio: 2000 / 3000,
    },
    {
      src:
      '/edito/WW/2_SLE141022_1255_RGB.jpg',
      ratio: 2000 / 3000,
      mediaLink: 'https://www.ysl.com/search?cgid=rings-women&utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing',
    } ]}}),_c('v-space',{style:({ height: 'var(--module-spacing-y-eoy)' })}),_c('v-solo',{style:({ padding: _vm.isMobile ? '0 32px' : '0 31.25vw' }),attrs:{"slides":[
    {
      mediaLink: 'https://www.ysl.com/search?cgid=holidays-arrivals&utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing',
      src:
      '/edito/WW/3_SLE141022_0707_RGB.jpg',
      ratio: 2000 / 3000,
    } ]}}),_c('v-space',{style:({ height: _vm.isMobile ? '64px' : '210px' })}),_c('v-duo',{style:({ padding: _vm.isMobile ? '0 5.33333vw' : '0 27vw' }),attrs:{"nocolumns":true,"gutter-size":'4px',"slides":[
    {
      mediaLink: 'https://www.ysl.com/shoppings/icare-maxi-shopping-en-cuir-d-agneau-matelasse-698651AAANG1000.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing',
      src:
        '/packshots/WW/Dyptique/1_698651AAANG1000_A.jpg',
      ratio: 2000 / 3260,
    },
    {
      src:
        '/packshots/WW/Dyptique/2_736443Y99562336_A.jpg',
      ratio: 2000 / 3260,
      mediaLink: 'https://www.ysl.com/sunglasses/sl-558-713741Y99011034.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing',
    } ]}}),_c('v-space',{style:({ height: _vm.isMobile ? '64px' : '210px' })}),_c('v-cover',{attrs:{"src":_vm.isMobile ? '/videos/3_mobile_eoy.mp4' : '/videos/3_eoy.mp4',"muteDisplay":true,"ratio":(_vm.isMobile) ? 9/16 : 16/9,"fetchPriority":'high',"lazy":'lazy'}}),_c('v-space',{style:({ height:_vm.isMobile ? '64px' : '210px' })}),_c('v-column-slideshow',{style:({ padding: _vm.isMobile ? '0 105px' : '0 9.8vw'}),attrs:{"slideshows":[[
    {
      mediaLink: 'https://www.ysl.com/cuffs-and-bracelets/thin-textured-bangle-in-metal-715549Y15008204.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing',
      src:
        '/packshots/WW/Tryptique Bracelets/1_715549Y15008204_A.jpg',
      ratio: 2000 / 3260,
    } ], [
    {
      mediaLink: 'https://www.ysl.com/cuffs-and-bracelets/tornado-crater-cuff-bracelet-in-metal-714975Y15008204.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing',
      src:
        '/packshots/WW/Tryptique Bracelets/2_714975Y15008204_A.jpg',
      ratio: 2000 / 3260,
    } ], [
    {
      mediaLink: 'https://www.ysl.com/cuffs-and-bracelets/bumpy-textured-cuff-bracelet-in-metal-716598Y15008204.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing',
      src:
        '/packshots/WW/Tryptique Bracelets/3_716598Y15008204_A.jpg',
      ratio: 2000 / 3260,
    } ]]}}),_c('v-space',{style:({ height:_vm.isMobile ? '64px' : '160px' })}),_c('v-solo',{style:({ padding: _vm.isMobile ? '0 8.76vw' : '0 31.25vw' }),attrs:{"slides":[
    {
      mediaLink: 'https://www.ysl.com/search?cgid=new-arrivals-jewellery-women&utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing',
      src:
      '/edito/WW/4_SLE141022_0892_RGB.jpg',
      ratio: 2000 / 3000,
    } ]}}),_c('v-space',{style:({  height: _vm.isMobile ? '64px' : '210px' })}),_c('div',{staticClass:"slideshow__custom"},[_c('v-swiper',{attrs:{"freeMode":true,"mouseWheel":!_vm.isMobile,"spaceBetween":4,"slidesView":_vm.isMobile ? 2 : 4,"slides":[
      {
        mediaLink: 'https://www.ysl.com/outerwear/coat-in-animal-free-fur-718539Y4F852503.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing',
        src:
          '/packshots/WW/Grand Slider/1_718539Y4F852503_A.jpg',
        ratio: 2000 / 3260,
      },
      {
        src:
          '/packshots/WW/Grand Slider/2_715627Y15268368_A.jpg',
        ratio: 2000 / 3260,
        mediaLink: 'https://www.ysl.com/rings/rhinestone-egg-ring-in-metal-715627Y15268368.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing',
      },
      {
        src:
          '/packshots/WW/Grand Slider/3_7175741UU001720_A.jpg',
        ratio: 2000 / 3260,
        mediaLink: 'https://www.ysl.com/high-heel-sandals/lila-sandals-in-crepe-satin-7175741UU001720.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing',
      },
      {
        src:
          '/packshots/WW/Grand Slider/4_724392Y76CV2020_A.jpg',
        ratio: 2000 / 3260,
        mediaLink: 'https://www.ysl.com/long-dress-in-viscose-724392Y76CV2020.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing',
      },
      {
        mediaLink: 'https://www.ysl.com/rings/rhinestone-edge-egg-ring-in-metal-712408Y15269577.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing',
        src:
          '/packshots/WW/Grand Slider/5_712408Y15269577_A.jpg',
        ratio: 2000 / 3260,
      },
      {
        src:
          '/packshots/WW/Grand Slider/6_7175721UU001000_A.jpg',
        ratio: 2000 / 3260,
        mediaLink: 'https://www.ysl.com/high-heel-sandals/cassie-sandals-in-crepe-satin-7175721UU001000.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing',
      }
    ]}})],1),_c('v-space',{style:({ height: _vm.isMobile ? '64px' : '210px' })}),_c('v-solo',{style:({ padding: _vm.isMobile ? '0 8.76vw' : '0 31.25vw' }),attrs:{"slides":[
    {
      mediaLink: 'https://www.ysl.com/sl-596-dune-736443Y99562336.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing',
      src:
      '/edito/WW/5_SLE141022_0194_RGB.jpg',
      ratio: 2000 / 3000,
    } ]}}),_c('v-space',{style:({ height: _vm.isMobile ? '64px' : '100px'  })}),_c('v-duo',{style:({ padding: _vm.isMobile ? '0 62px' : '0 10vw' }),attrs:{"gutter-size":'5.2vw',"slides":[
    {
      mediaLink: 'https://www.ysl.com/en-nl/tote-bags/icare-maxi-shopping-bag-in-quilted-lambskin-698651AAANG1000.html?utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing',
      src:
        '/edito/WW/6_SLE141022_1325_RGB.jpg',
      ratio: 720 / 1080,
    },
    {
      src:
        '/edito/WW/7_SLE141022_1192_RGB.jpg',
      ratio: 720 / 1080,
      mediaLink: 'https://www.ysl.com/search?cgid=holidays-arrivals&utm_source=digitaltool22&utm_medium=email&utm_campaign=digitaltool_eoy22&utm_content=email_marketing',
    } ]}}),_c('v-space',{style:({ height: _vm.isMobile ? '64px' : '100px' })}),_c('v-cover',{attrs:{"src":_vm.isMobile ? '/videos/2_mobile_eoy.mp4' : '/videos/2_bag_eoy.mp4',"muteDisplay":true,"logoImage":"/desktop/logo-drop-shadow.png","logo-image-width":['50%', '90%'],"ratio":16 / 9,"fetchPriority":'high',"lazy":'lazy'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }